import React, { useState, useEffect } from "react";
import "./styles/Gallery.css";

const transitionAuto = require("@alexspirgel/transition-auto");

export default function Gallery({
  images,
  albums,
  index,
  setIndex,
  isOpen,
  close,
}) {
  const albumName = albums.filter(
    (album) => album.id === images[index].album
  )[0].title;
  const composer = images[index].composer;
  const image = images[index].image;
  const date = images[index].date;
  const views = images[index].views;
  const description = images[index].description;
  const copyright = images[index].copyright;

  const [descriptionExpanded, setDescriptionExpanded] = useState(true);
  const [wait, setWait] = useState(false);

  useEffect(() => {
    document.body.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        close();
      }
    });
  }, [close]);

  function uploadDate() {
    const dateString = new Date(date);
    const dateDay = dateString.getDate();
    const day =
      dateDay.toString() +
      (dateDay === 1 || dateDay === 21 || dateDay === 31
        ? "st"
        : dateDay === 2 || dateDay === 22
        ? "nd"
        : dateDay === 3 || dateDay === 23
        ? "rd"
        : "th");
    const month = dateString.toLocaleString("default", { month: "long" });
    const year = dateString.getFullYear().toString();

    return `${month} ${day}, ${year}`.toUpperCase();
  }

  useEffect(() => {
    const descriptionElement = document.querySelector(
      "#gallery .description p"
    );

    if (descriptionExpanded === true) {
      descriptionElement.style.whiteSpace = "initial";
      setTimeout(() => (descriptionElement.style.height = "auto"), 400);

      transitionAuto({
        element: descriptionElement,
        property: "height",
        value: "auto",
      });
    } else {
      transitionAuto({
        element: descriptionElement,
        property: "height",
        value: "17px",
      });

      /*setExpandWidth(
        document
          .querySelector("#playing-video .expand-button > span")
          .getBoundingClientRect().width
      );*/
      setTimeout(() => {
        descriptionElement.style.whiteSpace = "nowrap";
        descriptionElement.style.height = "17px";
      }, 200);
    }
  }, [descriptionExpanded]);

  useEffect(() => {
    const descriptionElement = document.querySelector(
      "#gallery .description p"
    );

    if (!wait) {
      setWait(true);
      setTimeout(() => setWait(false), 400);

      if (descriptionExpanded === true) {
        descriptionElement.style.whiteSpace = "initial";
        setTimeout(() => (descriptionElement.style.height = "auto"), 400);

        transitionAuto({
          element: descriptionElement,
          property: "height",
          value: "auto",
        });
      } else {
        transitionAuto({
          element: descriptionElement,
          property: "height",
          value: "17px",
        });

        setTimeout(() => {
          descriptionElement.style.whiteSpace = "nowrap";
          descriptionElement.style.height = "17px";
        }, 200);
      }
    }
    // eslint-disable-next-line
  }, [descriptionExpanded]);

  return (
    <div id="gallery" data-open={isOpen} onClick={close}>
      <h4>{albumName}</h4>
      <button className="composer">{composer}</button>
      <div id="gallery-image-container">
        <a
          href={image}
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "contents" }}
          onClick={(e) => e.stopPropagation()}
        >
          {/* eslint-disable-next-line */}
          <img src={image} alt={`${albumName} image ${index + 1}`} />
        </a>
        <button
          onClick={
            index > 0
              ? (e) => {
                  setIndex(index - 1);
                  e.stopPropagation();
                }
              : (e) => e.stopPropagation()
          }
          id="galleryPrevious"
          data-disabled={index <= 0}
        >
          <svg width="35" height="66" viewBox="0 0 35 66" fill="none">
            <path d="M34 65L2 33L34 1" stroke="currentColor" strokeWidth="2" />
          </svg>
        </button>
        <button
          onClick={
            index < images.length - 1
              ? (e) => {
                  setIndex(index + 1);
                  e.stopPropagation();
                }
              : (e) => e.stopPropagation()
          }
          id="galleryNext"
          data-disabled={index >= images.length - 1}
        >
          <svg width="35" height="66" viewBox="0 0 35 66" fill="none">
            <path d="M1 1L33 33L1 65" stroke="currentColor" strokeWidth="2" />
          </svg>
        </button>
      </div>
      <p className="index">
        {index + 1} OF {images.length}
      </p>
      <div style={{ flexGrow: 1 }}></div>
      <div
        className="description"
        data-expanded={descriptionExpanded}
        onClick={(e) => {
          e.stopPropagation();
          if (!wait && !descriptionExpanded) {
            setDescriptionExpanded(!descriptionExpanded);
          }
        }}
      >
        <div className="top">
          <span>{uploadDate()}</span>
          <span style={{ fontSize: "16px", margin: "0 10px" }}>•</span>
          <span>{views.toLocaleString()} VIEWS</span>
        </div>
        <p>
          <div>
            <div
              style={{ display: "contents", cursor: "pointer" }}
              onClick={() => {
                if (!wait && descriptionExpanded) {
                  setDescriptionExpanded(!descriptionExpanded);
                }
              }}
            >
              <p dangerouslySetInnerHTML={{ __html: description }} />
              <button
                className="expand-button"
                title={
                  descriptionExpanded
                    ? "Minimize"
                    : "Expand"
                }
              >
                {/*<span style={{ "--width": `${expandWidth}px` }}>
                    {descriptionExpanded ? "SHOW LESS" : "SHOW MORE"}
                  </span>*/}
                <svg
                  height="12"
                  width="12"
                  viewBox="0 0 10 10"
                  style={{
                    transition: "transform 0.4s",
                    transform: `rotate(${
                      descriptionExpanded ? 225 : 0
                    }deg) scale(${descriptionExpanded ? 1.2 : 1})`,
                  }}
                >
                  <path
                    d="M10 5.71429H5.71429V10H4.28571V5.71429H0V4.28571H4.28571V0H5.71429V4.28571H10V5.71429Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
            <div className="social-media-icons">
              <div className="social-media-img">
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                  <img
                    draggable="false"
                    title="Facebook"
                    alt="facebook"
                    src="/images/connect-facebook.png"
                  />
                </a>
              </div>
              <div className="social-media-img">
                <a href="https://x.com" target="_blank" rel="noreferrer">
                  <img
                    draggable="false"
                    title="X"
                    alt="x"
                    src="/images/connect-x.png"
                  />
                </a>
              </div>
              <div className="social-media-img">
                <a href="https://youtube.com" target="_blank" rel="noreferrer">
                  <img
                    draggable="false"
                    title="YouTube"
                    alt="youtube"
                    src="/images/connect-youtube.png"
                  />
                </a>
              </div>
              <div className="social-media-img">
                <a href="https://rumble.com" target="_blank" rel="noreferrer">
                  <img
                    draggable="false"
                    title="Rumble"
                    alt="rumble"
                    src="/images/connect-rumble.png"
                  />
                </a>
              </div>
              <div className="social-media-img">
                <a href="https://twitch.com" target="_blank" rel="noreferrer">
                  <img
                    draggable="false"
                    title="Twitch"
                    alt="twitch"
                    src="/images/connect-twitch.png"
                  />
                </a>
              </div>
              <div className="social-media-img">
                <a
                  href="https://soundcloud.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    draggable="false"
                    title="SoundCloud"
                    alt="soundcloud"
                    src="/images/connect-soundcloud.png"
                  />
                </a>
              </div>
              <div className="social-media-img">
                <a href="https://patreon.com" target="_blank" rel="noreferrer">
                  <img
                    draggable="false"
                    title="Patreon"
                    alt="patreon"
                    src="/images/connect-patreon.png"
                  />
                </a>
              </div>
              <div className="social-media-img">
                <a href="https://discord.com" target="_blank" rel="noreferrer">
                  <img
                    draggable="false"
                    title="Discord"
                    alt="discord"
                    src="/images/connect-discord.png"
                  />
                </a>
              </div>
            </div>
            <div className="bottom">
              <span
                className="copyright"
                dangerouslySetInnerHTML={{ __html: copyright }}
              ></span>
              <div className="buttons">
                <button title="Album Details">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                  >
                    <path d="M7.2 4H8.8V5.6H7.2V4ZM7.2 7.2H8.8V12H7.2V7.2ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4Z" />
                  </svg>
                </button>
                <button title="Share">
                  <svg
                    width="13"
                    height="14"
                    viewBox="0 0 13 14"
                    fill="currentColor"
                  >
                    <path d="M10.5422 9.89558C10.008 9.89558 9.53012 10.1064 9.16466 10.4367L4.15361 7.52008C4.18875 7.35843 4.21687 7.19679 4.21687 7.02811C4.21687 6.85944 4.18875 6.69779 4.15361 6.53614L9.10843 3.64759C9.48795 3.999 9.98695 4.21687 10.5422 4.21687C11.7088 4.21687 12.6506 3.2751 12.6506 2.10843C12.6506 0.941767 11.7088 0 10.5422 0C9.3755 0 8.43373 0.941767 8.43373 2.10843C8.43373 2.27711 8.46185 2.43875 8.49699 2.6004L3.54217 5.48896C3.16265 5.13755 2.66365 4.91968 2.10843 4.91968C0.941767 4.91968 0 5.86145 0 7.02811C0 8.19478 0.941767 9.13655 2.10843 9.13655C2.66365 9.13655 3.16265 8.91867 3.54217 8.56727L8.54618 11.491C8.51104 11.6386 8.48996 11.7932 8.48996 11.9478C8.48996 13.0793 9.41064 14 10.5422 14C11.6737 14 12.5944 13.0793 12.5944 11.9478C12.5944 10.8163 11.6737 9.89558 10.5422 9.89558Z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </p>
      </div>
      <button onClick={close} id="galleryClose">
        <svg width="24" height="24" viewBox="0 0 34 34" fill="none">
          <path
            d="M1 1L33 33M33 1L1 33"
            stroke="currentColor"
            strokeWidth="2"
          />
        </svg>
      </button>
    </div>
  );
}