import React from "react";
import MediaCarousel from "./MediaCarousel.jsx";
import { SplideSlide } from "@splidejs/react-splide";
import Image from "./Image.jsx";
import "./styles/LatestImages.css";

export default function LatestImages({
  images,
  albums,
  viewAlbum,
  setCurrentMedia,
  setGalleryIndex,
  setGalleryOpen,
}) {
  return (
    <div id="latest-images">
      {images[0] !== "IMAGES_NOT_YET_LOADED" &&
      albums[0] !== "ALBUMS_NOT_YET_LOADED" &&
      images.length !== 0 ? (
        <>
          <MediaCarousel>
            {images.map((image, index) => (
              <SplideSlide>
                <Image
                  id={image.id}
                  title={image.title}
                  album={image.album}
                  thumbnail={image.thumbnail}
                  albums={albums}
                  viewAlbum={viewAlbum}
                  setCurrentMedia={setCurrentMedia}
                  index={index}
                  length={images.length}
                  openImage={() => {
                    setGalleryIndex(image.id);
                    setGalleryOpen(true);
                  }}
                />
              </SplideSlide>
            ))}
          </MediaCarousel>
        </>
      ) : (
        <span>loading...</span>
      )}
    </div>
  );
}