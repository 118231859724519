import React from "react";
import "./styles/Image.css";

export default function Image({
  id,
  title,
  album,
  thumbnail,
  albums,
  viewAlbum,
  setCurrentMedia,
  index,
  length,
  openImage,
}) {
  return (
    <div className="image">
      <div className="thumbnail" onClick={openImage}>
        <img src={thumbnail} alt={thumbnail} draggable="false" />
        <div className="overlay">
          <div className="bottom">
            <button onClick={openImage}>VIEW IMAGE</button>
            <span>
              {index + 1} OF {length}
            </span>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div>
          <button className="title">{title}</button>
          <a
            href={`/media?id=a${(album + 1).toString().padStart(2, "0")}`}
            onClick={(e) => e.preventDefault()}
            style={{ display: "contents" }}
          >
            <button
              className="composer"
              onClick={() => {
                viewAlbum(album);
                setCurrentMedia("album");
                window.history.pushState(
                  "",
                  "",
                  `/media?id=a${(album + 1).toString().padStart(2, "0")}`
                );
              }}
            >
              {albums.filter((thisAlbum) => thisAlbum.id === album)[0].title}
            </button>
          </a>
        </div>
        <button className="image-share" title="Share">
          <svg width="12" height="14" viewBox="0 0 12 14" fill="currentColor">
            <path d="M10 9.38667C9.49333 9.38667 9.04 9.58667 8.69333 9.9L3.94 7.13333C3.97333 6.98 4 6.82667 4 6.66667C4 6.50667 3.97333 6.35333 3.94 6.2L8.64 3.46C9 3.79333 9.47333 4 10 4C11.1067 4 12 3.10667 12 2C12 0.893333 11.1067 0 10 0C8.89333 0 8 0.893333 8 2C8 2.16 8.02667 2.31333 8.06 2.46667L3.36 5.20667C3 4.87333 2.52667 4.66667 2 4.66667C0.893333 4.66667 0 5.56 0 6.66667C0 7.77333 0.893333 8.66667 2 8.66667C2.52667 8.66667 3 8.46 3.36 8.12667L8.10667 10.9C8.07333 11.04 8.05333 11.1867 8.05333 11.3333C8.05333 12.4067 8.92667 13.28 10 13.28C11.0733 13.28 11.9467 12.4067 11.9467 11.3333C11.9467 10.26 11.0733 9.38667 10 9.38667Z" />
          </svg>
        </button>
      </div>
    </div>
  );
}