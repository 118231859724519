import React, { useEffect, useState } from "react";
import MediaTabs from "./MediaTabs";
import Latest from "./Latest";
import Albums from "./Albums";
import Other from "./Other";
import "./styles/MediaMain.css";
import Details from "./album/Details";
import Tracks from "./album/Tracks";
import Videos from "./album/Videos";
import Images from "./album/Images";

export default function MediaMain({
  setPath,
  playingType,
  setPlayingType,
  albumID,
  setAlbumID,

  /* Latest tracks */
  //path
  toMinutes,
  openPlayer,
  setList,
  playing,
  currentTime,
  setPlaying,
  currentPlaying,
  setCurrentPlaying,
  tracks,
  albums,

  /* Latest videos */
  videos,
  mouseDown,

  /* Gallery */
  images,
  setGalleryIndex,
  setGalleryOpen,
}) {
  const [currentMedia, setCurrentMedia] = useState("latest");
  const [requestScrollToTracks, setRequestScrollToTracks] = useState(false);

  useEffect(() => {
    function setPage() {
      const search = new URLSearchParams(window.location.search.toLowerCase());
      const page = search.get("");
      const id = search.get("id")?.match(/[a-zA-Z]+|[0-9]+/g);

      if (
        !id ||
        (id.length === 2 && id[0] === "a") ||
        (id.length === 4 &&
          id[0] === "a" &&
          (id[2] === "t" || id[2] === "v" || id[2] === "p"))
      ) {
        setPath(window.location.pathname);
        setCurrentMedia(page ? page : id ? "album" : "latest");
        setRequestScrollToTracks(false);

        if (id) {
          setAlbumID(Number(id[1]) - 1);

          switch (id[2]) {
            case "t":
              document.querySelector("#player").focus();
              setRequestScrollToTracks(true);
              setList(
                tracks
                  .filter((track) => track.album === Number(id[1]) - 1)
                  .map((track) => track.id)
              );
              setCurrentPlaying(
                tracks
                  .filter((track) => track.album === Number(id[1]) - 1)
                  .map((track) => track.id)[Number(id[3]) - 1]
              );
              openPlayer();
              break;
            case "v":
              break;
            case "p":
              break;
            default:
              break;
          }
        }
      } else {
        setPath("404");
      }
    }

    setPage();
    window.addEventListener("popstate", setPage);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "instant" });
  }, [currentMedia]);

  return (
    <MediaTabs
      //path={path}
      currentMedia={currentMedia}
      setCurrentMedia={setCurrentMedia}
    >
      {currentMedia === "latest" ? (
        <Latest
          playingType={playingType}
          setPlayingType={setPlayingType}
          setAlbumView={setAlbumID}
          setCurrentMedia={setCurrentMedia}
          /* Tracks */
          toMinutes={toMinutes}
          openPlayer={openPlayer}
          setList={setList}
          playing={playing}
          currentTime={currentTime}
          setPlaying={setPlaying}
          currentPlaying={currentPlaying}
          setCurrentPlaying={setCurrentPlaying}
          tracks={tracks}
          albums={albums}
          /* Videos */
          videos={videos}
          mouseDown={mouseDown}
          /* Gallery */
          images={images}
          setGalleryIndex={setGalleryIndex}
          setGalleryOpen={setGalleryOpen}
        />
      ) : currentMedia === "albums" ? (
        <Albums
          albums={albums}
          viewAlbum={setAlbumID}
          setCurrentMedia={setCurrentMedia}
          toMinutes={toMinutes}
          tracks={tracks}
        />
      ) : currentMedia === "other" ? (
        <Other />
      ) : currentMedia === "album" &&
        albumID !== null &&
        albums[0] !== "ALBUMS_NOT_YET_LOADED" &&
        tracks[0] !== "TRACKS_NOT_YET_LOADED" &&
        videos[0] !== "VIDEOS_NOT_YET_LOADED" &&
        images[0] !== "IMAGES_NOT_YET_LOADED" ? (
        <div key={albumID}>
          <Details
            albums={albums}
            allTracks={tracks}
            allVideos={videos}
            id={albumID}
            viewAlbum={setAlbumID}
            setCurrentMedia={setCurrentMedia}
            toMinutes={toMinutes}
          />
          <Tracks
            id={albumID}
            albums={albums}
            tracks={tracks}
            toMinutes={toMinutes}
            currentTime={currentTime}
            openPlayer={openPlayer}
            playing={playing}
            setPlaying={setPlaying}
            currentPlaying={currentPlaying}
            setCurrentPlaying={setCurrentPlaying}
            setList={setList}
            viewAlbum={setAlbumID}
            setCurrentMedia={setCurrentMedia}
            requestScrollToTracks={requestScrollToTracks}
          />
          <Videos
            videos={videos.filter((video) => video.album === albumID)}
            albums={albums}
            playingType={playingType}
            setPlayingType={setPlayingType}
            toMinutes={toMinutes}
            mouseDown={mouseDown}
            viewAlbum={setAlbumID}
            setCurrentMedia={setCurrentMedia}
          />
          <Images
            images={images.filter((image) => image.album === albumID)}
            albums={albums}
            viewAlbum={setAlbumID}
            setCurrentMedia={setCurrentMedia}
            setGalleryIndex={setGalleryIndex}
            setGalleryOpen={setGalleryOpen}
          />
        </div>
      ) : (
        <></>
      )}
    </MediaTabs>
  );
}
