import React from "react";
import MediaHeading from "./MediaHeading";
import { SplideSlide } from "@splidejs/react-splide";
import MediaCarousel from "./MediaCarousel.jsx";
import Album from "./Album.jsx";
import "./styles/Albums.css";

export default function Albums({
  albums,
  viewAlbum,
  setCurrentMedia,
  toMinutes,
  tracks,
}) {
  return (
    <section id="albums">
      <MediaHeading link={{ url: "#", text: "VIEW ALL" }}>ALBUMS</MediaHeading>
      <div className="content">
        <h2 className="subtitle">PERSONAL</h2>
        <MediaCarousel>
          {albums.map((album) => (
            <SplideSlide>
              <Album
                id={album.id}
                name={album.title}
                image={album.mediumImagePath}
                composer={album.composer}
                height="small"
                viewAlbum={() => {
                  viewAlbum(album.id);
                  setCurrentMedia("album");
                }}
                toMinutes={toMinutes}
                tracks={tracks}
              />
            </SplideSlide>
          ))}
        </MediaCarousel>
        <h2 className="subtitle">PROJECTS</h2>
        <MediaCarousel>
          {albums.map((album) => (
            <SplideSlide>
              <Album
                id={album.id}
                name={album.title}
                image={album.imagePath}
                composer={album.composer}
                height="big"
                viewAlbum={() => {
                  viewAlbum(album.id);
                  setCurrentMedia("album");
                }}
                toMinutes={toMinutes}
                tracks={tracks}
              />
            </SplideSlide>
          ))}
        </MediaCarousel>
        <h2 className="subtitle">WORK IN PROGRESS</h2>
        <MediaCarousel>
          {albums.map((album) => (
            <SplideSlide>
              <Album
                id={album.id}
                name={album.title}
                image={album.imagePath}
                composer={album.composer}
                height="big"
                viewAlbum={() => {
                  viewAlbum(album.id);
                  setCurrentMedia("album");
                }}
                toMinutes={toMinutes}
                tracks={tracks}
              />
            </SplideSlide>
          ))}
        </MediaCarousel>
      </div>
    </section>
  );
}