import React, { useState, useEffect } from "react";
import "./styles/Video.css";

export default function Video({
  album,
  setPlayingType,
  setVideoM,
  setPlaying,
  id,
  videos,
  playingIndex,
  index,
  setIndex,
  name,
  image,
  preview,
  toMinutes,
  viewAlbum,
}) {
  const [video, setVideo] = useState("ELEMENT_NOT_YET_LOADED");
  const [previewVideo, setPreviewVideo] = useState("ELEMENT_NOT_YET_LOADED");
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState("--:--");
  const [menuOpen, setMenuOpen] = useState(false);
  const [userRating, setUserRating] = useState("UNRATED");

  useEffect(() => {
    setVideo(
      document.querySelector(`.splide__slide:not(.splide__slide--clone) .${id}`)
    );
  }, [id]);

  let time;

  useEffect(() => {
    if (video !== "ELEMENT_NOT_YET_LOADED") {
      setPreviewVideo(video.querySelector("video"));
    }
  }, [video]);

  useEffect(() => {
    if (previewVideo !== "ELEMENT_NOT_YET_LOADED") {
      previewVideo.addEventListener("loadedmetadata", () => {
        setDuration(toMinutes(video.querySelector("video").duration));
      });
    }
    // eslint-disable-next-line
  }, [previewVideo]);

  function start() {
    previewVideo.currentTime = 0;
    previewVideo.play();
    time = setInterval(() => {
      setCurrentTime(previewVideo.currentTime);
    }, 100);
  }

  function stop() {
    if (previewVideo.playing) {
      previewVideo.pause();
    }
    previewVideo.currentTime = 0;
    clearTimeout(time);
  }

  useEffect(() => {
    if (currentTime >= 10) {
      video.querySelector("video").currentTime = 0;
    }
  }, [video, currentTime]);

  function playVideo() {
    setIndex(index);
    const video = document.querySelector("#video");
    const thumbnailE = document.querySelector("#thumbnail");
    const player = document.querySelector("#video-player");
    setVideoM(video);
    document.querySelector("#playing-video").scrollIntoView();
    thumbnailE.style.opacity = "0";
    thumbnailE.style.opacity = "default";
    player.style.bottom = "0";
    setPlaying(true);
    video.play();
    setPlayingType("video");
  }

  function like() {
    if (userRating === "POSITIVE") {
      setUserRating("UNRATED");
    } else {
      setUserRating("POSITIVE");
    }
  }

  function dislike() {
    if (userRating === "NEGATIVE") {
      setUserRating("UNRATED");
    } else {
      setUserRating("NEGATIVE");
    }
  }

  return (
    <div className={`video ${id}`} onMouseEnter={start} onMouseLeave={stop}>
      <div className="video-image" onClick={playVideo}>
        <img src={image} alt={name} draggable="false" />
        <video
          data-duration={duration}
          src={preview}
          loop={true}
          muted={true}
        ></video>
        <div className="overlay">
          <div className="top">
            <button
              className="video-menu"
              title="Menu"
              onClick={(e) => {
                e.stopPropagation();
                setMenuOpen(true);
              }}
            >
              <svg width="18" height="12" viewBox="0 0 18 12" fill="#dddddd">
                <path d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" />
              </svg>
            </button>
          </div>
          <div className="bottom">
            <button
              className="play-video"
              onClick={(e) => {
                e.stopPropagation();
                playVideo();
              }}
            >
              PLAY VIDEO
            </button>
            <button
              className="view-video"
              style={{ pointerEvents: "none" }}
              onClick={(e) => e.stopPropagation()}
            >
              {duration}
            </button>
          </div>
          <div
            className="video_menu"
            data-open={menuOpen}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="close"
              title="Close"
              onClick={() => setMenuOpen(false)}
            >
              <svg width="12" height="12" viewBox="0 0 12 12" fill="#dddddd">
                <path d="M12 1.20857L10.7914 0L6 4.79143L1.20857 0L0 1.20857L4.79143 6L0 10.7914L1.20857 12L6 7.20857L10.7914 12L12 10.7914L7.20857 6L12 1.20857Z" />
              </svg>
            </button>
            <h4>ALBUM MENU</h4>
            <div>
              <button className="option" aria-label="DETAILS">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="#dddddd">
                  <path d="M7.2 4H8.8V5.6H7.2V4ZM7.2 7.2H8.8V12H7.2V7.2ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4Z" />
                </svg>
              </button>
              <button className="option" aria-label="FEATURED">
                <svg width="16" height="15" viewBox="0 0 16 15" fill="#dddddd">
                  <path d="M14.5455 0H1.45455C0.654545 0 0 0.654545 0 1.45455V10.1818C0 10.9818 0.654545 11.6364 1.45455 11.6364H6.54545V13.0909H5.09091V14.5455H10.9091V13.0909H9.45455V11.6364H14.5455C15.3455 11.6364 16 10.9818 16 10.1818V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 10.1818H1.45455V1.45455H14.5455V10.1818Z" />
                </svg>
              </button>
              <button className="option" aria-label="PURCHASE">
                <svg width="10" height="16" viewBox="0 0 10 16" fill="#dddddd">
                  <path d="M4.8711 7.02222C2.85333 6.49778 2.20444 5.95556 2.20444 5.11111C2.20444 4.14222 3.10222 3.46667 4.60444 3.46667C6.18666 3.46667 6.77332 4.22222 6.82666 5.33333H8.7911C8.72888 3.80444 7.3511 2.23111 5.49332 1.77778V0H3.71555V1.77778C1.9911 2.15111 0.16 3.41333 0.16 5.12889C0.16 7.18222 1.85778 8.20444 4.33777 8.8C6.55999 9.33333 7.00444 10.1156 7.00444 10.9422C7.00444 11.5556 6.56888 12.5333 4.60444 12.5333C2.77333 12.5333 2.05333 11.7156 1.95556 10.6667H0C0.106667 12.6133 2.00888 13.8578 3.71555 14.2222V16H5.49332V14.2222C7.22666 13.8933 9.04888 12.7556 9.04888 10.9333C9.04888 8.40889 6.88888 7.54667 4.8711 7.02222Z" />
                </svg>
              </button>
              <button className="option" aria-label="SHARE">
                <svg width="13" height="14" viewBox="0 0 13 14" fill="#dddddd">
                  <path d="M10.5422 9.89558C10.008 9.89558 9.53012 10.1064 9.16466 10.4367L4.15361 7.52008C4.18875 7.35843 4.21687 7.19679 4.21687 7.02811C4.21687 6.85944 4.18875 6.69779 4.15361 6.53614L9.10843 3.64759C9.48795 3.999 9.98695 4.21687 10.5422 4.21687C11.7088 4.21687 12.6506 3.2751 12.6506 2.10843C12.6506 0.941767 11.7088 0 10.5422 0C9.3755 0 8.43373 0.941767 8.43373 2.10843C8.43373 2.27711 8.46185 2.43875 8.49699 2.6004L3.54217 5.48896C3.16265 5.13755 2.66365 4.91968 2.10843 4.91968C0.941767 4.91968 0 5.86145 0 7.02811C0 8.19478 0.941767 9.13655 2.10843 9.13655C2.66365 9.13655 3.16265 8.91867 3.54217 8.56727L8.54618 11.491C8.51104 11.6386 8.48996 11.7932 8.48996 11.9478C8.48996 13.0793 9.41064 14 10.5422 14C11.6737 14 12.5944 13.0793 12.5944 11.9478C12.5944 10.8163 11.6737 9.89558 10.5422 9.89558Z" />
                </svg>
              </button>
              <div
                style={{
                  width: "1px",
                  height: "40px",
                  backgroundColor: "#505050",
                  margin: "-5px 15px",
                }}
              ></div>
              <button
                className="option"
                aria-label="LIKE"
                data-liked={userRating === "POSITIVE"}
                onClick={like}
              >
                <svg width="16" height="15" viewBox="0 0 16 15" fill="#dddddd">
                  <path d="M9.57818 0L4.36364 5.22182V14.5455H13.3164L16 8.29091V5.09091H9.95636L10.7709 1.17818L9.57818 0ZM0 5.81818H2.90909V14.5455H0V5.81818Z" />
                </svg>
              </button>
              <button
                className="option"
                aria-label="DISLIKE"
                data-disliked={userRating === "NEGATIVE"}
                onClick={dislike}
              >
                <svg width="16" height="15" viewBox="0 0 16 15" fill="#dddddd">
                  <path d="M6.42182 14.5455L11.6364 9.32364V0H2.68364L0 6.25455V9.45455H6.04364L5.22909 13.3673L6.42182 14.5455ZM13.0909 0H16V8.72727H13.0909V0Z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div>
          <button className="title">{name}</button>
          <button className="composer">{album.composer}</button>
        </div>
        <button className="video-share" title="Share">
          <svg width="12" height="14" viewBox="0 0 12 14" fill="currentColor">
            <path d="M10 9.38667C9.49333 9.38667 9.04 9.58667 8.69333 9.9L3.94 7.13333C3.97333 6.98 4 6.82667 4 6.66667C4 6.50667 3.97333 6.35333 3.94 6.2L8.64 3.46C9 3.79333 9.47333 4 10 4C11.1067 4 12 3.10667 12 2C12 0.893333 11.1067 0 10 0C8.89333 0 8 0.893333 8 2C8 2.16 8.02667 2.31333 8.06 2.46667L3.36 5.20667C3 4.87333 2.52667 4.66667 2 4.66667C0.893333 4.66667 0 5.56 0 6.66667C0 7.77333 0.893333 8.66667 2 8.66667C2.52667 8.66667 3 8.46 3.36 8.12667L8.10667 10.9C8.07333 11.04 8.05333 11.1867 8.05333 11.3333C8.05333 12.4067 8.92667 13.28 10 13.28C11.0733 13.28 11.9467 12.4067 11.9467 11.3333C11.9467 10.26 11.0733 9.38667 10 9.38667Z" />
          </svg>
        </button>
      </div>
    </div>
  );
}
